enum itemCategories {
  'ANTIQUES' = 2,
  'JEWELRY' = 3,
  'BOATS_YACHTS' = 4,
  'INDUSTRIAL_MACHINERY' = 5,
  'AGRICULTURAL_MACHINERY' = 6,
  'INDUSTRIAL_MATERIALS' = 7,
  'FURNITURE' = 8,
  'MOTORCYCLES_SCOOTERS_QUADS' = 9,
  'CLOTHING' = 10,
  'TRAILERS_SEMITRAILERS' = 12,
  'LORRIES' = 13,
  'CARS' = 14,
  'HOUSEHOLD_APPLIANCES' = 15,
  'HARDWARE' = 16,
  'RTV_EQUIPMENT' = 17,
  'RAW_MATERIALS' = 18,
  'OTHER_MOVABLE_PROPERTY' = 19,
  'GARAGES_PARKING_PLACES' = 102,
  'LAND' = 103,
  'HOUSES' = 104,
  'FLATS' = 105,
  'COMMERCIAL_PREMISES' = 106,
  'WAREHOUSES' = 107,
  'MARINE_VESSELS' = 108,
  'ESTATE' = 109
}
export default itemCategories;
