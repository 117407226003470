import { useRoute } from 'vue-router';

import mainCategories from '@Models/enums/mainCategories';
import itemCategories from '@Models/enums/itemCategories';
import { EMainCategories } from '@Models/Item/filter.model';
import type { IItem } from '@Models/Item/item.model';
import type { IBreadcrumb } from '@Models/breadcrumb.model';
import routesName from '@Enums/routesName';

export const useBreadcrumbs = () => {
  const route = useRoute();
  const { $i18n, $routeNamesWithoutLocalize } = useNuxtApp();

  const pagesNames: { name: string; routeName: string }[] = [
    {
      name: $i18n.t('breadcrumbs.login') as string,
      routeName: routesName.LOGIN
    },
    {
      name: $i18n.t('breadcrumbs.register') as string,
      routeName: routesName.REGISTER
    },
    {
      name: $i18n.t('breadcrumbs.loginReminder') as string,
      routeName: routesName.LOGIN_REMINDER
    },
    {
      name: $i18n.t('breadcrumbs.passwordReminder') as string,
      routeName: routesName.PASSWORD_REMINDER
    },
    {
      name: $i18n.t('breadcrumbs.safetyRules') as string,
      routeName: routesName.SAFETY_RULES
    },
    {
      name: $i18n.t('breadcrumbs.rules') as string,
      routeName: routesName.RULES
    },
    {
      name: $i18n.t('breadcrumbs.privacyPolicy') as string,
      routeName: routesName.PRIVACY_POLICY
    },
    {
      name: $i18n.t('breadcrumbs.myAccount') as string,
      routeName: routesName.BAILIFF_MY_ACCOUNT
    },
    {
      name: $i18n.t('breadcrumbs.myAccount') as string,
      routeName: routesName.USER_MY_ACCOUNT
    },
    {
      name: $i18n.t('breadcrumbs.myAuctions') as string,
      routeName: $routeNamesWithoutLocalize.user.myAuctions
    },
    {
      name: $i18n.t('breadcrumbs.manageBidderAccount') as string,
      routeName: routesName.BAILIFF_EDIT_BIDDER_ACCOUNT
    },
    {
      name: $i18n.t('breadcrumbs.bailiffFaqs') as string,
      routeName: routesName.BAILIFF_FAQS
    },
    {
      name: $i18n.t('breadcrumbs.faq') as string,
      routeName: routesName.FAQS
    },
    {
      name: $i18n.t('breadcrumbs.report') as string,
      routeName: routesName.SEND_REPORT
    },
    {
      name: $i18n.t('breadcrumbs.itemList') as string,
      routeName: routesName.ITEM_LIST
    },
    {
      name: $i18n.t('breadcrumbs.complaints') as string,
      routeName: routesName.USER_COMPLAINTS
    }
  ];
  let defaultBreadcrumbs: IBreadcrumb[] = [];

  const clearBreadcrumbs = () => {
    defaultBreadcrumbs = [];
  };

  const setBreadcrumbs = () => {
    clearBreadcrumbs();

    const fullPath = route.fullPath;

    const params = fullPath.substring(1).split('/');
    let path = '';
    params.forEach((param: string) => {
      path = `${path}/${param}`;
      let name = route.name as string;
      if (name != null && 0 > name.search('all')) {
        pagesNames.find((el) => {
          if (name.replace(/([__])\w+/, '') === el.routeName) {
            defaultBreadcrumbs.push(el);
          }
        });
      }
    });
    defaultBreadcrumbs = [...new Set(defaultBreadcrumbs)];
  };

  setBreadcrumbs();

  const setBreadcrumbsForItem = (item: any): IBreadcrumb[] => {
    const mainCategory =
      item.auctionCategory === EMainCategories[mainCategories.MOVABLE]
        ? EMainCategories.MOVABLE_NAME
        : EMainCategories.REAL_ESTATE_NAME;

    return [
      {
        name: $i18n.t(`mainCategory.${item.auctionCategory}`) as string,
        routeName: routesName.ITEM_LIST,
        query: { mainCategory: mainCategory }
      },
      {
        name: setCategoryName(item.itemCategory.key),
        routeName: routesName.ITEM_LIST,
        query: {
          mainCategory: mainCategory,
          subCategory: setCategoryName(item.itemCategory.key)
        }
      },
      {
        name: item.name,
        href: `#`
      }
    ];
  };

  const setBidderBreadcrumbsInAuctionDetails = (item: IItem | { name: string; id: string }): IBreadcrumb[] => {
    return [
      ...setBasicRoute(item),
      {
        name: $i18n.t('breadcrumbs.auctionDetails') as string,
        routeName: routesName.USER_ITEMS_DETAILS_ITEM_ID,
        params: {
          id: item.id
        }
      }
    ];
  };

  const setBidderBreadcrumbsInNotes = (item: IItem): IBreadcrumb[] => {
    const base = setBidderBreadcrumbsInAuctionDetails(item);
    return [
      ...base,
      {
        name: $i18n.t('breadcrumbs.note') as string,
        href: '#'
      }
    ];
  };

  const setBidderBreadcrumbsInSingleComplaint = (item: { name: string; id: string }): IBreadcrumb[] => {
    const base = setBidderBreadcrumbsInAuctionDetails(item);
    return [
      ...base,
      {
        name: $i18n.t('breadcrumbs.complaint') as string,
        href: '#'
      }
    ];
  };

  const setSimpleComplaintBreadcrumbs = (): IBreadcrumb[] => {
    return [
      {
        name: $i18n.t('breadcrumbs.complaints') as string,
        href: '/user/complaints'
      },
      {
        name: $i18n.t('breadcrumbs.complaint') as string,
        href: '#'
      }
    ];
  };

  const setFaqBreadcrumbs = (name: string, question: string): IBreadcrumb[] => {
    const page = pagesNames.find((el) => {
      if (el.routeName === name) return el;
    });
    return [
      {
        name: page?.name as string,
        routeName: page?.routeName as string
      },
      {
        name: question,
        routeName: '#'
      }
    ];
  };

  const setBasicRoute = (item: IItem | { name: string; id: string }): IBreadcrumb[] => {
    return [
      {
        name: $i18n.t(`navbar.myAuctions`) as string,
        routeName: $routeNamesWithoutLocalize.user.myAuctions as string,
        hash: '#started'
      },
      {
        name: item.name,
        routeName: routesName.ITEMS_ID,
        params: {
          id: item.id
        }
      }
    ];
  };

  const setCategoryName = (key: number): string => {
    const name: any = {
      [itemCategories.ANTIQUES]: `${$i18n.t('categories.antiques')}, ${$i18n.t('categories.art')}`,
      [itemCategories.JEWELRY]: `${$i18n.t('categories.jewelry')}, ${$i18n.t('categories.watches')}`,
      [itemCategories.BOATS_YACHTS]: `${$i18n.t('categories.boats')}, ${$i18n.t('categories.yachts')}`,
      [itemCategories.INDUSTRIAL_MACHINERY]: $i18n.t('categories.industrialMachinery'),
      [itemCategories.AGRICULTURAL_MACHINERY]: $i18n.t('categories.agriculturalMachinery'),
      [itemCategories.INDUSTRIAL_MATERIALS]: $i18n.t('categories.industrialMaterials'),
      [itemCategories.FURNITURE]: $i18n.t('categories.furniture'),
      [itemCategories.MOTORCYCLES_SCOOTERS_QUADS]: `${$i18n.t('categories.motorcycles')}, ${$i18n.t('categories.scooters')}, ${$i18n.t('categories.quads')}`,
      [itemCategories.CLOTHING]: $i18n.t('categories.clothing'),
      [itemCategories.TRAILERS_SEMITRAILERS]: `${$i18n.t('categories.trailers')}, ${$i18n.t('categories.semiTrailers')}`,
      [itemCategories.LORRIES]: $i18n.t('categories.lorries'),
      [itemCategories.CARS]: $i18n.t('categories.cars'),
      [itemCategories.HOUSEHOLD_APPLIANCES]: $i18n.t('categories.householdAppliances'),
      [itemCategories.HARDWARE]: $i18n.t('categories.hardware'),
      [itemCategories.RTV_EQUIPMENT]: $i18n.t('categories.RTVEquipment'),
      [itemCategories.RAW_MATERIALS]: $i18n.t('categories.rawMaterials'),
      [itemCategories.OTHER_MOVABLE_PROPERTY]: $i18n.t('categories.otherMovableProperty'),
      [itemCategories.GARAGES_PARKING_PLACES]: `${$i18n.t('categories.garages')}, ${$i18n.t('categories.parkingPlaces')}`,
      [itemCategories.LAND]: $i18n.t('categories.land'),
      [itemCategories.HOUSES]: $i18n.t('categories.houses'),
      [itemCategories.FLATS]: $i18n.t('categories.flats'),
      [itemCategories.COMMERCIAL_PREMISES]: $i18n.t('categories.commercialPremises'),
      [itemCategories.WAREHOUSES]: $i18n.t('categories.warehouses'),
      [itemCategories.MARINE_VESSELS]: $i18n.t('categories.marineVessels'),
      [itemCategories.ESTATE]: $i18n.t('categories.estate')
    };
    return name[key];
  };

  return {
    setCategoryName,
    setBasicRoute,
    setFaqBreadcrumbs,
    setSimpleComplaintBreadcrumbs,
    setBidderBreadcrumbsInSingleComplaint,
    setBidderBreadcrumbsInNotes,
    setBidderBreadcrumbsInAuctionDetails,
    setBreadcrumbsForItem,
    defaultBreadcrumbs
  };
};
