<template>
  <div
    class="breadcrumbs"
    v-if="breadcrumbs"
  >
    <span class="breadcrumbs__element">
      <NuxtLink
        class="link"
        :to="{ name: $routeNames.index }"
        :title="$t('breadcrumbs.index')"
      >
        {{ $t('breadcrumbs.index') }}
      </NuxtLink>
    </span>
    <template
      v-for="(breadcrumb, index) in breadcrumbs"
      :key="index"
    >
      <span
        v-if="breadcrumbs.length - 1 > index"
        :class="[
          'breadcrumbs__element',
          { 'breadcrumbs__element--shrink': breadcrumb.routeName === routesName.ITEMS_ID }
        ]"
      >
        <CdsIcon
          class="icon"
          icon="chevron_right"
        />

        <NuxtLinkLocale
          v-if="breadcrumb.routeName"
          class="link text-capitalize"
          :to="customRoute(breadcrumb)"
          :title="breadcrumb.name"
        >
          {{ breadcrumb.name || $t('empty') }}
        </NuxtLinkLocale>
        <a
          v-else
          class="link text-capitalize"
          :href="breadcrumb.href"
          :title="breadcrumb.name"
        >
          {{ breadcrumb.name }}
        </a>
      </span>
      <span
        v-else
        :class="[
          'breadcrumbs__element',
          {
            'breadcrumbs__element--shrink': $route.name === $routeNames.item(Number($route.params.id))
          }
        ]"
      >
        <CdsIcon
          class="icon"
          icon="chevron_right"
        />
        {{ breadcrumb.name }}</span
      >
    </template>
  </div>
</template>

<script lang="ts" setup>
import { useBreadcrumbs } from '@Composables/breadcrumbs';

import type { IBreadcrumb } from '@Models/breadcrumb.model';
import routesName from '@Enums/routesName';

const props = defineProps({
  default: {
    type: Array,
    default: []
  }
});

const useBreadcrumb = useBreadcrumbs();

const breadcrumbs = computed((): IBreadcrumb[] | any => {
  if (props.default.length) return props.default;
  return useBreadcrumb.defaultBreadcrumbs.filter((breadcrumb) => breadcrumb.name);
});

function customRoute(breadCrumb: IBreadcrumb) {
  return {
    name: breadCrumb.routeName,
    params: breadCrumb.params,
    query: breadCrumb.query,
    hash: breadCrumb.hash
  };
}
</script>

<style lang="scss" scoped>
@import '@Assets/scss/breadcrumbs.scss';
</style>
