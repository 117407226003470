enum routeNames {
  'INDEX_'= 'index___',
  'LOGIN_' = 'login___',
  'LOGIN' = 'login',
  'LOGIN_REMINDER' = 'login-reminder',
  'REGISTER_' = 'register___',
  'REGISTER' = 'register',
  'PASSWORD_REMINDER' = 'password-reminder',
  'SAFETY_RULES' = 'safety-rules',
  'ITEMS_ID' = 'items-id',
  'USER_ITEMS_DETAILS_ITEM_ID' = 'user-items-details-itemId',
  'BAILIFF_ITEMS_DETAILS_ITEM_ID' = 'bailiff-items-details-itemId',
  'RULES' = 'rules',
  'PRIVACY_POLICY' = 'privacy-policy',
  'BAILIFF_MY_ACCOUNT' = 'bailiff-my-account',
  'USER_MY_ACCOUNT' = 'user-my-account',
  'USER_MY_AUCTIONS' = 'user-my-auctions',
  'BAILIFF_FAQS' = 'bailiff-faqs',
  'FAQS' = 'faqs',
  'SEND_REPORT' = 'send-report',
  'ITEM_LIST' = 'item-list',
  'USER_COMPLAINTS' = 'user-complaints',
  'BAILIFF_ITEMS_DETAILS_ID_BIDDER_BIDDER_ID' = 'bailiff-items-details-id-bidder-bidderId',
  'BAILIFF_EDIT_BIDDER_ACCOUNT' = 'bailiff-edit-bidder-account'
}

export default routeNames;